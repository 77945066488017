<template>
  <div>
      <div class="breadcrumb">
        <h1>Asistencia Médico</h1>
      </div>
      <div class="row mb-4">
        <div class="col-sm-12 mb-4">
          <div class="card text-left">
            <div class="card-header" style="height:80px;">
              <div class="row" style="padding: inherit">
                <div class="col-sm-4">
                </div>
                <div class="col-sm-3 offset-sm-5">
                  <div class="text-right">
                    <label class="switch switch-success mr-3">
                      <input type="checkbox" id="filter"
                      :checked="estado"
                      v-model="estado">
                      <span class="slider"></span>
                      <span>{{ labelActivos }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row py-3">
                <div class="col-8">
                  <p style="margin-top: -35px;">Dr. Mario Paniagua</p>
                </div>
                <div class="col-4 text-right">
                  <p style="color:red; margin:auto;">Pendiente: {{pendientes.length}} </p>
                </div>
              </div>
              <div class="row">
                <div class="col-3 p-3" v-for="(pendiente, index) in pendientes" :key="index">
                  <div class="col-12 p-3 bg-gray border-radius">
                    <div class="col-12 p-0">
                      <div class="row m-0 p-0">
                        <div class="col-8 p-0 text-left">
                          <i
                            @click="onClickEditarIcon()"
                            class="nav-icon i-Pen-5 font-weight-bold text-success mr-3"
                            style="cursor: pointer"
                          ></i>
                        </div>
                        <div class="col-4 text-right">
                          <p><strong>{{pendiente.numero}}</strong></p>
                        </div>
                      </div>
                    </div>
                    <div><h5><strong>Paciente</strong></h5></div>
                    <div><h6><strong>{{pendiente.paciente}}</strong></h6></div>
                    <br>
                    <br>
                    <div class="row">
                      <div class="col-sm-8" style="font-size:11px; margin-block-start: auto;">
                        <div class="pt-2" style="color:white">
                          <strong>Origen: </strong>
                          {{pendiente.origen}}
                        </div>
                        <div class="row">
                          <div class="col-12" style="color:white">
                            <strong>Fecha: </strong>{{pendiente.fecha}}
                            <strong>H: </strong>{{pendiente.hora}}
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="logo">
                            <img style="border-radius: 120px;"
                            :src="require('@/assets/images/faces/16.jpg')">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'asistenciaIndex',
  data() {
    return {
      estado: true,
      pendientes: [
        {
          numero: 'Turno 1',
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
        {
          numero: 'Turno 2',
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
        {
          numero: 'Turno 3',
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
        {
          numero: 'Turno 4',
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
        {
          numero: 'Turno 5',
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
        {
          numero: 'Turno 6',
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
        {
          numero: 'Turno 7',
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
        {
          numero: 'Turno 8',
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
      ],
    };
  },
  computed: {
    labelActivos() {
      if (this.estado) {
        return 'Cerrados';
      }
      return 'Inactivos';
    },
  },
  methods: {
    onClickEditarIcon() {
      this.$router.push({ name: 'atencion-medico-edit' });
    },
    onClickEliminarIcon() {

    },
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: rgb(182, 182, 182);
}
.border-radius {
  border-radius: 10px;
}
.logo {
  width: 60px;
  height: 60px;
  margin: 0;
  margin-left: auto;
  display: block;
}
</style>
